<template>
  <v-scale-transition
    mode="out-in"
  >
    <div
      v-if="isAuthenticated"
      class="light-theme pb-10"
    >
      <v-row
        no-gutters
        class="col-9 col-sm-12 col-lg-10 col-xl-5 mx-auto mt-n2
         mt-3 pa-0 flex-column justify-center"
      >
        <v-row
          class="text--accent-4 pa-5 font-weight-bold v-size--large"
          style="font-size: xx-large; font-family: Roboto, sans-serif"
        >
          Поширені питання
        </v-row>
        <v-row justify="center">
          <v-expansion-panels accordion style="overflow: hidden">
            <v-expansion-panel
              v-for="(item,i) in questions"
              :key="i"
            >
              <v-expansion-panel-header class="font-weight-bold">
                {{ item.q_text }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.q_answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-row>
    </div>
  </v-scale-transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FAQ',
  title: 'Питання та відповіді',
  components: {
  },
  data() {
    return {
      showDropDown: null,
      questions: [
        {
          q_text: 'Як замовити довідку про закриття договору?',
          q_answer: 'Замовити довідку можна в особистому кабінеті'
            + ' або написати звернення на пошту info@sfactor.com.ua',
        },
        {
          q_text: 'Як закрити кредит?',
          q_answer: 'Кредит закривається автоматично, достатньо'
            + ' дізнатися залишок і після сплати через два'
            + ' дні перевірити статус закриття.',
        },
        {
          q_text: 'Як сплатити без % ?',
          q_answer: 'Без % можна сплатити через особистий кабінет.',
        },
        {
          q_text: 'Як зайти до особистого кабінету?',
          q_answer: 'Для входу в особистий кабінет, потрібен телефон,'
            + ' який вказували при оформленні, та ІПН. Ви отримаєте СМС'
            + ' з кодом від нашої компанії для підтвердження.',
        },
        {
          q_text: 'Чому після сплати не зменшується залишок? ',
          q_answer: 'Ваш платіж буде відображатись на авансовому'
            + ' рахунку та покриє тіло кредиту після розрахункової дати.',
        },
        {
          q_text: 'До якого числа потрібно сплачувати?',
          q_answer: 'Дата сплати це - дата оформлення кредиту, рекомендуємо сплачувати за 5 днів.',
        },
        {
          q_text: 'Як дізнатись залишок по договору?',
          q_answer: 'Перевірити залишок можна або в особистому кабінеті,'
            + ' або звернувшись до колл - центра компанї.',
        },
        {
          q_text: 'Як повернути помилково сплачені кошти або невірне зарахування?',
          q_answer: 'Для повернення надлишково сплачених коштів /'
            + ' зарахування платежу за договором, необхідно'
            + ' надати квитанцію про сплату та написати заяву за зразком.'
            + ' Надати документи ви зможете звернувшись на гарячу лінію, або в особистому кабінеті.',
        },
        {
          q_text: 'Я погасив кредит. Коли я зможу подати заявку на наступний?',
          q_answer: 'Наступний кредит Ви можете взяти відразу після повного погашення попереднього.'
          + ' Погасити позику при цьому можна в Особистому кабінеті, банківським переказом,'
          + ' за допомогою інтернет-банкінгу або в терміналі самообслуговування.'
          + ' Після того як в Особистому кабінеті з\'явиться інформація про оплату поточного кредиту,'
          + ' Ви зможете подати заявку на отримання нового.',
        },
        {
          q_text: 'Після оплати рахунку було знято більше грошей. Що робити?',
          q_answer: 'Ми ніколи не беремо комісію за погашення кредиту.'
            + ' Рекомендуємо звернутися в Ваш банк і уточнити тарифи'
            + ' обслуговування - можливо, банк віднімає комісію за розрахунки карткою.'
            + ' Крім того, Ви можете дізнатися про рух коштів по рахунку, попросивши'
            + ' виписку з Вашого банку.',
        },
        {
          q_text: 'Що буде, якщо я не виплачу кредит?',
          q_answer: 'У разі умисного ухилення від виплати кредиту,'
            + ' компанія починає процес стягнення боргу в примусовому порядку'
            + ' через суд. Крім того, інформація, про Вас і Вашу заборгованость буде'
            + ' передано, в тому числі, і в Кредитне Бюро України, що в подальшому'
            + ' негативно вплине на Ваші майбутні спроби оформити кредит у всіх банках'
            + ' і фінансових установах країни.',
        },
        {
          q_text: 'Куди мені звертатись у разі неможливості виконати свої зобов\'язання вчасно?',
          q_answer: 'В разі виникнення питань щодо врегулювання '
            + 'простроченої заборгованості ви можете звертатися за'
            + ' номером телефону відділу врегулювання простроченої '
            + 'заборгованості: 0443550920 з 09:00 до 19:00 з пн-пт,'
            + ' вих: сб, нд, а також святкові дні.',
        },
        {
          q_text: 'Чи можливо погасити кредит раніше строку?',
          q_answer: 'Усі клієнти можуть погашати кредити/позики раніше терміну,'
            + ' при цьому відсоток нараховується тільки за фактичний час'
            + ' користування кредитними коштами.',
        },
        {
          q_text: 'Як змінити телефонний номер?',
          q_answer: 'Будь-ласка зателефонуйте за номером (044) 593 66 33'
            + ' та повідомте оператору про зміну вашого фінансового номеру телефону.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
  beforeMount() {

  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {
    onClickToHomeHandler() {
      this.$router.push({ path: '/' });
    },
    minusClickHandler(index) {
      if (index === this.showDropDown) {
        this.showDropDown = null;
      } else {
        this.showDropDown = index;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.v-list-item {
  min-height: auto !important;
}
.borderr{
  border: 2px solid red;
}
.butt{
  margin: 10px 0px 10px 0px;
  max-width: 90%;
  align-self: center;
}
.divider_container {
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
}
</style>
